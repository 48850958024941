
.button-full{
    float: left;
    width: 100%;
    padding: 0 $grid-gutter-width / 2; 
    .btn{
        margin-left: 0 !important;
        margin-right: 0 !important;
        width: 100% !important;
    }
}
.button-half{
    float: left;
    width: 50%;
    padding: 0 $grid-gutter-width / 2; 
    .btn{
        margin-left: 0 !important;
        margin-right: 0 !important;
        width: 100% !important;
    }   
}
.col-sm-4, .col-md-3, .col-lg-2{
    .button-half{
        width:100%;
        @media (min-width:1690px){
            width: 50%;
        }
    }
}