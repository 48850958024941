// Glyphicons font path
$icon-font-path: "../fonts/";
// Grid settings
$main-sm-columns: 12;
$sidebar-sm-columns: 4;
// Colors
$brand-primary: #003da2;
.no-padding {
    padding: 0 !important;
}

.no-padding-left {
    padding-left: 0 !important;
}

.no-padding-right {
    padding-right: 0 !important;
}

.no-margin {
    margin: 0 !important;
}

// Responsive Breaks
@mixin breakpoint($class) {
    @if $class==xs {
        @media (max-width: 767px) {
            @content;
        }
    }
    @else if $class==sm {
        @media (min-width: 768px) {
            @content;
        }
    }
    @else if $class==mdm {
        @media (min-width: 768px) and (max-width: 992px) {
            @content;
        }
    }
    @else if $class==md {
        @media (min-width: 992px) {
            @content;
        }
    }
    @else if $class==lg {
        @media (min-width: 1200px) {
            @content;
        }
    }
    @else if $class==xlg {
        @media (min-width: 1600px) {
            @content;
        }
    }
    @else if $class==iPortrait {
        @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
            @content;
        }
    }
    @else if $class==iLandscape {
        @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
            @content;
        }
    }
    @else {
        @warn "Breakpoint mixin supports: xs, sm, md, lg, iPortrait and iLandscape only!";
    }
}