// TIDY START

@media (max-width:$screen-xs-max){
    #wpadminbar{
        display: none !important;
    }
}
header {
    .scottish-hockey-header {
        width: 100%;
    }
    .globalheader {
        background: rgb(242, 242, 242);
        min-height: 40px;
        margin-bottom: 0;
        border: none;
        padding-top: 0;
        position: relative;
        z-index: 50;
        nav {
            &.global-nav {
                display: block;
                padding-top: 0px;
                min-height: 40px;
                ul {
                    list-style-type: none;
                    padding-left: 0px;
                    display: block;
                    min-height: 40px;
                    margin-bottom: 0px;
                    @media(max-width:$screen-xs-max){
                        background: rgba(0,0,0,.05);
                        margin-top: 0;
                    }
                    li {
                        display: block;
                        float: left;
                        width: 50%;
                        @include breakpoint(sm) {
                            border-right: 1px solid rgb(184, 184, 184);
                            width: auto;
                        }
                        &.menu-latest-results {
                            @include breakpoint(sm) {
                                border-left: 1px solid rgb(184, 184, 184);
                            }
                        }
                        a {
                            color: #5a5a5a;
                            font-size: 14px;
                            padding: 10px;
                            font-weight: bolder;
                            text-transform: uppercase;
                            transition: .5s all ease;
                            @include breakpoint(sm) {
                                padding: 15px;
                            }
                            &:hover {
                                background-color: #909090;
                                color: #fff;
                            }
                        }
                    }
                }
            }
        }
        .login-head {
            color: #5a5a5a;
            font-weight: 600;
            text-transform: uppercase;
            font-size: 14px;
            padding: 15px;
            line-height: 20px;
            position: relative;
            display: block;
            @media (max-width:$screen-xs-max){
                padding-left: 30px;
            }
            img {
                position: absolute;
                left: -20px;
                top: 10px;
                width: 25px;
                @media (max-width:$screen-xs-max){
                    left: 0;
                }
            }
            a {
                color: #5a5a5a;
                text-decoration: none;
            }
        }
        .small-nav {
            a {
                color: #5a5a5a;
                padding-left: 15px;
                margin-left: 10px;
                font-weight: 600;
                text-transform: uppercase;
            }
        }
    }
    
    .top-navigation {
        background: rgb(0, 61, 162);
        padding: 10px 0 0 0;
        @include breakpoint(lg) {
            padding: 30px 0 20px;
        }
        .navbar-collapse {
            @include breakpoint(xs) {
                padding: 0;
                box-shadow: none;
                border: 0;
            }
        }
        @media (min-width: 1220px) and (max-width: 1450px) {
            .navWrap {
                width: 83.3333333333%;
            }
            .socialWrap {
                width: 75%;
            }
        }
        ul {
            &.shortcut-nav {
                display: block;
              //  width: calc(100% - 30px);
              margin-top: 14px;
                @include breakpoint(lg) {
                    margin-top: 18px;
                }
                li {
                    float: none;
                    display: inline-block;
                    &.active,
                    &.current-menu-ancestor {
                        a {
                            border-bottom: 2px solid #ffffff;
                            padding-bottom: 11px;
                        }
                    }
                    a {
                        font-size: 14px;
                        color: #ffffff;
                        font-weight: 600;
                        transition: .5s all ease;
                        padding: 8px 6px !important;
                        @media (min-width: 1330px) and (max-width: 1449px) {
                            font-size: 14px;
                            padding: 10px 17px !important;
                        }
                        @media (min-width: 1450px) {
                            font-size: 15px;
                            padding: 15px 10px !important;
                        }
                        &:hover {
                            background: #144eaf;
                            text-shadow: 2px 2px 10px rgba(0, 0, 0, 0.5);
                        }
                    }
                }
            }
            &.mobileNav {
                margin: 0;
                li {
                    @include breakpoint(xs) {
                        width: 100%;
                        display: block;
                    }
                    a {
                        @include breakpoint(xs) {
                            width: 100%;
                            display: block;
                            color: #fff;
                            font-weight: normal;
                        }
                    }
                }
            }
        }
        .sh-logo {
            float: left;
            margin-bottom: 10px;
            min-width: 100px;
            max-width: 100px;
            @include breakpoint(xs) {
                display: block;
                margin-left: auto;
                margin-right: auto;
            }
            @include breakpoint(sm) {
                display: block;
            }
            @include breakpoint(md) {}
            @include breakpoint(lg) {}
        }
        .navbar-toggle {
            margin: 15px 0 0;
            padding: 10px;
            border: 1px solid rgb(255, 255, 255);
            border-radius: 0;
            span {
                background-color: #fff;
            }
        }
    }
    .social {
        @include breakpoint(xs) {
            float: none;
            text-align: right;
        }
        @include breakpoint(md) {
            margin-top: 12px;
            float: right;
        }
        @include breakpoint(lg) {
            margin-top: 16px;
        }
        @media (min-width: 1450px) {
            margin-top: 22px;
        }
        li {
            @include breakpoint(xs) {
                display: inline-block;
                float: none;
            }
            @include breakpoint(sm) {
                display: inline-block;
                float: none;
            }
            @include breakpoint(md) {
                float: left;
            }
            a {
                color: #91baff;
                font-size: 16px;
                @include breakpoint(sm) {
                    
                    font-size: 21px;
                    display: inline-block;
                    padding: 10px;
                }
                &:hover {
                    background: transparent;
                    color: #fff;
                    @include breakpoint(sm) {
                        
                        background: #003da2;
                        text-shadow: 2px 2px 10px rgba(0, 0, 0, 0.5);
                    }
                }
            }
        }
    }
    #global-search {
        form {
            position: relative;
            .global-search-input {
                border: 0;
                margin: 0;
                padding: 15px 20px;
                width: 100%;
                &:focus {
                    outline: none;
                }
            }
            .btn-global-search {
                padding: 15px;
                background-color: #5a5a5a;
                color: #fff;
                border-radius: 0;
                margin: 0px;
                position: absolute;
                top: 0;
                right: 0;
                transition: all .5s ease;
                &:hover {
                    background-color: lighten(#5a5a5a, 20%);
                }
            }
        }
        &.sb-search{
            @media (max-width:$screen-xs-max){
                margin-left: - $grid-gutter-width / 2;
                margin-right: - $grid-gutter-width / 2;
            }
        }
        
    }
    
    #st-trigger-effects {
        button {
            border: none;
            padding: 0px;
            background: #003da2;
            color: #fff;
            font-size: 1em;
            cursor: pointer;
            display: inline-block;
            margin: 0px;
            border-radius: 0px;
            position: absolute;
            height: 60px;
            margin-top: 25px;
            z-index: 30;
            &.close {
                position: absolute;
                z-index: 20;
                top: 0;
                opacity: 1;
            }
        }
    }
    .feature-link {
        padding-top: 75px;
        .quick-links {
            ul {
                list-style-type: none;
                margin: 0;
                padding: 0px;
                li {
                    a {
                        display: block;
                        width: 100%;
                        padding: 10px 15px;
                        color: #fff;
                        text-decoration: none;
                        font-weight: 700;
                        transition: all .5s ease;
                        &:hover {
                            background: #144eaf;
                        }
                    }
                }
            }
        }
    }
}
.mp-trigger-wrap{
    float: right;
    //padding: 8px 10px;
    margin-top: 18px;
    padding: 11px 15px;
    font-size: 21px;
    @media (min-width:$screen-sm-max){
        display: none;
    }
  /*  @media (min-width:$screen-lg-min){
        margin-top: 18px;
        padding: 11px 10px;
    }*/
    a{
        color: #fff;
        cursor: pointer;
    }
}
.mp-sp-home{
    width: 100%;
    a{
        display: block;
        text-align: center;
        opacity: .5;
        margin-bottom: 10px;
        margin-top: 10px;
        img{
           // display: inline-block;
           margin-left: 60px;
        }
    }
}
.mp-menu{
    .closeNav{
        color: #fff;
        left: 10px;
        position: absolute;
        float: left;
        margin-top: 11px;
        font-size: 19px
    }
}
#mp-menu.mp-menu ul{
    width: 90%;
    margin-left: 10%;
}
#mp-menu.mp-menu ul li>a{
    border: 0 none;
    padding: 12px 10px;
    font-size: 18px;
    font-weight: bold;
}
.mp-menu .mp-back{
    font-family: proxima-nova;
    text-transform: capitalize;
    font-weight: bold;
    &:hover,
    &:focus{
        font-weight: bold;
    }
}
.mp-menu > .mp-level>.menu>li> .mp-level2{
    margin-top: 110px;
}

// TIDY END
.fa.circle-border {
    border-radius: 50%;
    padding: 10px;
    width: 33px;
    height: 33px;
    text-align: center;
    text-decoration: none;
    color: #003da2;
    background-color: #f2f2f2;
    font-weight: 700;
    font-size: 15px;
}

.navTopTrigger {
    position: absolute;
    right: 20px;
    top: 10px;
    background: #363c3d;
    text-decoration: none;
    z-index: 30;
    cursor: pointer;
    .open {
        background: #cbcbcb;
        color: #363c3d;
        outline: none;
    }
}

header.banner .globalheader .navTopTrigger.open+.navbar-nav {
    background: #363c3d;
    height: auto;
    max-height: 800px;
}

header.banner .globalheader .navbar-nav {
    right: 15px;
    position: absolute;
    height: 52px;
    max-height: 52px;
    margin-top: 0;
    margin-left: -15px;
    margin-right: -15px;
    -webkit-transition: all .2s linear;
    transition: all .2s linear;
    overflow: hidden;
    width: 100%;
    text-align: center;
    z-index: 20;
}

//
.social {
    /* position: absolute;
    bottom: 115px;
    margin-right:15px; */
    ul {
        text-align: center;
        height: auto;
        li {
            display: inline-block;
            padding: 5px;
            margin-bottom: 30px !important;
            a {
                font-size: 20px;
            }
        }
    }
}

#mp-menu.mp-menu .mp-social{
    height: auto;
    width: auto;
    position: absolute;
    right: 0;
    li{
        display: inline-block;
        a{
            padding: 15px;
        }
    }
}