footer {
    position: relative;
    background: #003da2;
    padding: 15px 0px;
    z-index: 105;

    /** Left panel of the footer **/
    .newsletter-box {
        @include breakpoint(xs) {
            width: 100%;
        }

        @include breakpoint(md) {
            width: 70%;
        }

        text-align: left;

        margin: 50px 0 0 0;
        padding-left: 20px;

        p {
            color: #fff;
            font-size: 13px;
        }

        .email-box {
            width: calc(100% - 35px);
            padding: 0 15px;
            height: 35px;
            background-color: #042864;
            border: 0 none;
            color: #fff;
            @media (min-width:$screen-sm-min) and (max-width:$screen-lg-min){
                //width: 100%;
            }
        }

        .newsletter-btn {
            position: absolute;
            height: 35px;
            width: 35px;
            color: #fff;
            background-color: #042864;
            border: 0 none;
            border-left: 1px solid #fff;
        }
    }

    /** Middle panel of the footer **/
    .social {
        float: none;
        margin-top: 30px;

        padding-left: 15px;
        @include breakpoint(xs) {
            width: 100%;
        }
        @include breakpoint(sm) {
            width: 100%;
            text-align: center;
        }
        @include breakpoint(md) {
            width: 100%;
            text-align: center;
        }
        @include breakpoint(lg) {
            width: 100%;
            text-align: center;
        }

        li{ display: inline-block; float: none;
            a { color: #fff; font-size: 25px;  display: inline-block; }
            a:hover { color: #91baff; background: #003da2; text-shadow: 2px 2px 10px rgba(0,0,0,0.5);}
        } }

    .copyright {
        color: #fff;
        text-align: center;
        padding: 15px;
        @include breakpoint(xs) {
            text-align: left !important;
        }

    }

    /** Right panel of the footer **/
    .footer-right-box {
        @include breakpoint(xs) {
            margin: 20px;
        }
        @include breakpoint(sm) {
            margin: 20px;
        }
        @include breakpoint(md) {
            margin: 50px 20px 40px 20px;
        }
        @include breakpoint(lg) {
            margin: 50px 20px 40px 20px;
        }

        ul { display: inline-block; float: right;
             li { list-style-type: none; float: left;
                  a { color: #91baff; font-weight: 600; padding: 0 5px; }
             }
        }
        .footer-nav-mid {
            border-left: 2px solid #fff;  
            //border-right: 2px solid #fff;
        }

        a.b2footerlink { float:right; text-align:left; display: inline-block; font-size:11px; margin-top: 20px; color: #91baff; font-weight: 700; }
        a.b2footerlink img { margin-top:3px; }
        a.b2footerlink:hover { opacity: 1; text-decoration:none; }
        .b2footerlink img { max-width: 100%; height: auto; display: block; }
    }

    .btn-scroll-top-f{
        border-top: 1px solid #003da2;
        border-bottom: 1px solid #003da2;
        margin-bottom: 15px;
        padding-top: 20px;
        width: 100%;
        color: white;
        padding-bottom: 30px;
        text-decoration: none;
        &:hover,&:focus{
            text-decoration: none;
            background: lighten(#003da2, 10%);
        }
        >span{
            display: block;
            text-align: center;
            width: 100%;
        }
        >span.fa{
            font-size: 18px;
        }
        >span+span{
            font-family: 'Raleway', sans-serif;
            font-size: 14px;
            text-transform: uppercase;
        }
    }
}

.blue-stripe-footer {
    display: block;
    position: relative;
    width: 100%;
    height: 10px;
    background: #003da2;
    border-bottom: 4px solid #fff;
    z-index: 60;
}