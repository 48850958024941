// FONTS TO USE
// font-family: 'Libre Baskerville', serif;
// font-family: 'Hind', sans-serif;
// font-family: "proxima-nova";
html {
    body {
        font-family: "proxima-nova";
    }
}

.modal-backdrop {
    opacity: .8 !important;
    background-color: darken($brand-primary, 25%) !important;
}

.modal {
    .modal-body {
        h2 {
            margin: 0 0 20px;
            color: $brand-primary;
            font-weight: bold;
        }
    }
    .modal-header {
        border: 0;
        h3 {
            font-size: 15px;
            text-transform: uppercase;
            margin: 3px 0 0 0;
            color: #cacaca;
            float: left;
        }
    }
}

.main {
    float: none !important;
    width: 100% !important;
    padding: 0px !important;
}

.reduced-grid {
    // Add to all container fluids
    @include breakpoint(xlg) {
        padding-left: 130px;
        padding-right: 130px;
    }
}

.btn {
    border-radius: 0 !important;
}

.colouredGrid {
    background-color: #eaeaea;
}

.blue-stripe {
    display: block;
    position: relative;
    width: 100%;
    height: 10px;
    background: #003da2;
    border-top: 4px solid #fff;
    z-index: 60;
}

.popover
{
    &.popover-large {
     max-width:660px;
 }
}

.unconfirmed-popover{
    float:right;
}
// h1,
// h2,
// h3,
// h4,
// h5 {
//     font-family: 'Hind', sans-serif;
// }