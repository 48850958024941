.recent-posts {
    padding: 5px 0;
    list-style:none;
    li {
        padding:5px 0;
        border-bottom:1px solid #ddd;
        a {
            text-decoration:none;
            text-transform:none;
            color:#000;
            transition:all 0.5s ease;
            &:hover {
                color:$brand-primary;
                text-decoration:none;
                text-transform:none;
            }
        }
    }
}
.clubhead {
    background-color:$brand-primary;
    color:#fff;
}

.division-page {
    padding-bottom:65px;
    text-align:center;
    h2 {
        font-size:20px !important;
        text-align:center !important;
    }
    img {
        width:90%;
    }
}
.staff, .player {
    cursor: pointer;
}
.competitions-section {
    padding:35px 0;
        h2 {
            min-height: 66px !important;
        }
}
.events-title {
    background-color: #fff;
    z-index: 3;
    position: relative;
    display:inline-block;
    left:15px;
}
.events-area {
    border: solid 1px #ddd;
    margin-top:-25px;
    padding:20px 15px;
    z-index:1;
}
.back-btn {
    display: block;
    background-color: #003da2;
    color: #fff;
    width: 180px;
    max-width: 100%;
    margin: 10px 0;
    padding: 10px 0;
    transition: all .5s ease;
    text-align: center;
    &:hover {
        background-color: #00173c;
        text-decoration: none;
        color:#fff;
    }
}

.blogNavigation {
    ul {
        padding: 0px;
        li {
            display: inline-block;
            list-style-type: none;
            a {
                display: block;
                width: auto;
                height: 40px;
                border: 1px solid;
                text-align: center;
                padding: 10px 10px 0px;
                transition: 0.5s all ease;
                border-color: #e0d8d8;
                color: #003da2;
                &:hover {
                    background-color: #fff;
                    color: #000;
                    text-decoration: none;
                }
            }
        }
    }
}
