input[type='text'],
input[type='email'],
input[type='password'],
input[type='search'],
textarea,
select{
    font-size: 16px;
}

.btn-transparent {
    background: transparent;
    border: 0 none;
}

.background-poster {
    position: fixed;
    left: 50%;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    z-index: -100;
    -ms-transform: translateX(-50%) translateY(-50%);
    -moz-transform: translateX(-50%) translateY(-50%);
    -webkit-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
}

.homePanel, .homePanel>.stretch{
    @media (max-width:$screen-sm-max){
        min-height: 240px;
        padding: 10px 15px !important;
    }
}
.feature-panel {

    @media (min-width: 1220px) and (max-width: 1340px) {
        min-height: 650px;
    }
    @media (min-width: 1341px) {
        min-height: 600px;
    }
    background-size: cover;
    background-position: 50%;
    position: relative;
    font-family: 'Libre Baskerville',serif;
    .logo {
        padding: 30px 0px 20px 0px;
        max-height: 180px;
    }
    h1 {
        color: #fff;
        text-transform: uppercase;
        margin: 5px auto 30px;
        @media (min-width: 1220px) and (max-width: 1440px) {
            font-size: 35px;
        }
        @media (min-width: 1450px) {
            font-size: 45px;
        }
    }
    p {
        color: #ffffff;
        &:first-of-type {
            font-weight: bold;
            margin-bottom: 25px;
            @media (min-width: 1220px) and (max-width: 1440px) {
                font-size: 18px;
            }
            @media (min-width: 1450px) {
                font-size: 24px;
            }
        }
        font-size: 18px;
    }
    .layer {
        background-color: rgba(0, 61, 162, 0.9);
        position: relative;
        float: left;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        padding-bottom: 65px;
        @media (min-width:1220px){
            position: absolute;
        }
    }
}


.feature-box-wrapper {
    position: relative;
    @media (max-width:$screen-sm-max){
        background: #fff;
    }
    @include breakpoint(md) {
        /*top: -30px;*/
    }
    @include breakpoint(lg) {
        /*top: -60px;*/
        /*padding: 0 115px;*/
    }
    .feature-box-container{
        @media (min-width:$screen-sm-min){
            float: left;
            padding: $grid-gutter-width / 2;
            width: 50%;
        }
    }
    .feature-box {
        @include breakpoint(md) {
            position: relative;
            top: -30px;
        }
        @include breakpoint(lg) {
            position: relative;
            top: -60px;
        }
        .bottom-pan-img {
            background-size: cover;
            background-position: 50%;
        }
        .bottom-pan-content {
            background-color: #fff;
            padding: $grid-gutter-width / 2;
            @include breakpoint(lg) {
                padding: 20px 30px;
            }
            span,
            h3,
            p {
                color: #003da2;
                text-transform: uppercase;
            }
            h3 {
                margin: 5px 0;
                font-weight: bold;
            }
            span {
                font-size: 10px;
            }
            a {
                display: block;
                background-color: #003da2;
                color: #fff;
                text-transform: uppercase;
                width: 180px;
                max-width: 100%;
                margin: 10px 0;
                padding: 10px 0;
                transition: .5s all ease;
                text-align: center;
                &:hover {
                    background-color: darken(#003da2, 20%);
                    text-decoration: none;
                }
            }
        }
        .people-content {
            @include breakpoint(lg) {
                padding-top: 10px;
            }
            span,
            h3,
            p {
                color: #003da2;
                text-transform: uppercase;
            }
            h3 {
                margin: 5px 0;
                font-weight: bold;
            }
            span {
                font-size: 10px;
            }
            a {
                display: block;
                background-color: #003da2;
                color: #fff;
                text-transform: uppercase;
                width: 180px;
                max-width: 100%;
                margin: 10px 0;
                padding: 10px 0;
                transition: .5s all ease;
                text-align: center;
                &:hover {
                    background-color: darken(#003da2, 20%);
                    text-decoration: none;
                }
            }
        }
        .stretch {
            background-size: cover;
            background-position: 50%;
        }
    }
}

.news-wr {
    //    top: 90px !important;
    .feature-box{
        top:0;
    }
}

.sponsor {
    h3 {
        text-align: center;
        color: grey;
        padding-bottom: 20px;
    }
    .sponsor-logos {
        padding-bottom: 20px;
    }
}

.block-wrapper {
    margin-bottom: 30px;
    border: 1px solid hsla(0, 0%, 50%, .3);
    h3 {
        width: 100%;
        height: 40px;
        background: #003da2;
        color: #fff;
        font-size: 16px;
        margin: 0;
        font-weight: 700;
        padding-left: 10px;
        line-height: 40px;
        position: relative;
        z-index: 110;
        text-transform: uppercase;
    }
    .wid-content {
        margin: 25px;
        font-size: 14px;
    }
    .wrap-btn {
        background-color: #eae6e7;
        margin: 0 5px 10px;
        min-width: 90%;
        text-transform: capitalize;
        color: #003da2;
        font-size: 11px;
        font-weight: 700;
        padding-top: 5px;
        padding-bottom: 5px;
        background-color: #eae6e7;
        margin: 0 5px 10px;
        min-width: 90%;
        text-transform: capitalize;
        color: #003da2;
        font-size: 11px;
        font-weight: 700;
        padding-top: 5px;
        padding-bottom: 5px;
    }
    .mini-logo {
        vertical-align: sub !important;
    }
}

.left-menu {
    background-color: #f2f2f2;
    padding: 10px;
    ul {
        margin: 0;
        padding: 0;
        list-style: none;
        overflow: auto;
        li {
            a {
                display: block;
                color: #5a5a5a;
                font-size: 12px;
                min-height: 25px;
                padding: 0px 5px;
                font-weight: 600;
                text-transform: uppercase;
            }
        }
    }
}

.breadcrumbs {
    border-bottom: 0.5px solid #eae6e7;
    p {
        padding-top: 15px;
        font-size: 16px;
    }
    a {
        color: #003da2;
    }
    a:hover {
        text-decoration: none;
        color: #000;
    }
}

.internal-page {
    padding-top: 40px;
    padding-bottom: 40px;
    .related-links-panel {
        min-height: 200px;
        position: relative;
        background-size: cover;
        background-position: 50%;
        margin-bottom: 10px;
        h1 {
            color: #ffffff;
            font-size: 50px; //
        }
        p {
            color: #fff;
        }
        .layer {
            background-color: rgba(0, 61, 162, 0.55);
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            h3 {
                color: #fff;
            }
            h3:hover {
                color: #000;
            }
        }
    }
    .main-content {
        border-bottom: 0.5px solid #eae6e7;
    }
    .related-repeater {
        padding-top: 10px;
    }
    .event-title {
        border-bottom: .5px solid #eae6e7;
    }
    .info {
        padding-top: 5px;
    }
}

.news-wr {
    .feature-box {
        background-color: #f2f2f2;
        min-height: 180px;
        margin-bottom: 10px;
        .feat-img {
            padding: 10px;
        }
        .bottom-pan-content {
            background-color: #f2f2f2;
        }
    }
}

.archive-event-box {
    width: 100%;
    .feature-box {
        background-color: #f2f2f2;
        min-height: 180px;
        margin-bottom: 10px;
        .feat-img {
            padding: 10px;
            max-height: 250px;
        }
        .bottom-pan-content {
            background-color: #f2f2f2;
            padding: 15px;
            @include breakpoint(lg) {
                padding: 20px 30px;
            }
            span,
            h3,
            p {
                color: #003da2;
                text-transform: uppercase;
            }
            h3 {
                margin: 5px 0;
                font-weight: bold;
            }
            span {
                font-size: 11px;
            }
            a {
                display: block;
                background-color: #003da2;
                color: #fff;
                text-transform: uppercase;
                width: 180px;
                max-width: 100%;
                margin: 10px 0;
                padding: 10px 0;
                transition: .5s all ease;
                text-align: center;
                &:hover {
                    background-color: darken(#003da2, 20%);
                    text-decoration: none;
                }
            }
            .location {
                display: block;
            }
            .time {
                display: block;
            }
            .date {
                display: block;
            }
        }
    }
}

// JS STYLES
.main-banner {
    padding: 15px 0;
    .main-banner-wrapper {
        .banner-mid-feature {
            position: relative;
            min-height: 100%;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            @media (min-width:$screen-md-min){
                position: absolute;
            }
            .banner-feature {
                min-height: 100%;
                background-size: cover;
                background-position: 75% 50%;
                @media (max-width:$screen-sm-max){
                    height: 400px;
                    margin-bottom: 30px;
                }
                .img-caption-slider {
                    position: relative;
                    background-color: rgba(255, 255, 255, 0.7);
                    padding: 10px;
                    @media (max-width:$screen-sm-max){
                        position: absolute;
                        bottom: 0;
                    }
                    /*opacity: 0.6;*/
                    @include breakpoint(xs) {
                        width: 100%;
                    }
                    @include breakpoint(sm) {
                        /* width: 100%;
                         position: absolute;
                         bottom: 120px;
                        */}
                    @include breakpoint(md) {
                        position: absolute;
                        width: 85%;
                        bottom: 120px;
                    }
                    @include breakpoint(lg) {
                        width: 70%;
                        bottom: 120px;
                    }
                    h2 {
                        color: #042864;
                        font-weight: 700;
                        @include breakpoint(md) {
                            font-size: 35px;
                        }
                        @include breakpoint(lg) {
                            font-size: 40px;
                        }
                    }
                    .feat-subtitle {
                        font-size: 14px;
                        color: #042864;
                        font-weight: 700;
                    }
                    .blue-btn {
                        background-color: rgb(4, 40, 100);
                        padding: 5px 20px 5px 20px;
                        color: white;
                        min-width: 100%;
                        font-weight: 700;
                        margin: 10px auto;
                        text-transform: uppercase;
                    }
                }
            }
        }
        .banner-left-feature {
            position: relative;
            width: 100%;
            height: 100%;
            background-size: cover;
            background-position: 50% 50%;
            @include breakpoint(sm) {
                // min-height: 450px;
            }
            @include breakpoint(md) {
                // min-height: 600px;
            }
            @include breakpoint(lg) {
                min-height: 100%;
            }
            .img-caption {
                background-color: #003da2;
                padding: 10px;
                @include breakpoint(xs) {
                    width: 100%;
                }
                @include breakpoint(sm) {
                    width: 100%;
                    position: absolute;
                    bottom: 120px;
                }
                @include breakpoint(md) {
                    width: 90%;
                    bottom: 120px;
                }
                @include breakpoint(lg) {
                    width: 100%;
                    bottom: 120px;
                }
                @media (min-width:1280px){
                    width: 95%;
                }

                h2 {
                    @include breakpoint(md) {
                        font-size: 15px;
                    }
                    @include breakpoint(lg) {
                        font-size: 20px;
                    }
                    color: #fff;
                    font-weight: 700;
                    margin: 5px auto 20px;
                    line-height: 25px;
                }
                .feat-subtitle {
                    font-size: 11px;
                    color: white;
                    font-weight: 700;
                }
                .blue-btn {
                    text-transform: uppercase;
                    text-align: left;
                    background-color: #042864;
                    padding: 10px;
                    color: #fff;
                    width: 100%;
                    font-weight: 700;
                    margin: 10px auto 0;
                    display: block;
                }
            }
        }
        .banner-right-feature {
            .latest-news-box {
                margin-bottom: $grid-gutter-width / 2; //30px;
                border: 1px solid rgba(128, 128, 128, 0.3);
                background-color: #fff;
                h3 {
                    width: 100%;
                    height: 40px;
                    background: #003da2;
                    color: #FFF;
                    font-size: 16px;
                    margin: 0px;
                    font-weight: 700;
                    padding-left: 10px;
                    line-height: 40px;
                    position: relative;
                    z-index: 110;
                    text-transform: uppercase;
                }
                p {
                    margin: 25px;
                    font-size: 14px;
                }
                .mini-logo {
                    vertical-align: sub !important;
                }
                .btn-article{
                    background-color: #eae6e7;
                    margin: 10px;
                    text-transform: uppercase;
                    color: #003da2;
                    font-size: 14px;
                    font-weight: 700;
                    padding: 5px 15px !important;
                    width: calc(100% - 20px);
                    @media (min-width:$screen-sm-min){
                        width: auto;
                    }
                }
            }
            .events-box {
                margin-bottom: 30px;
                border: 1px solid rgba(128, 128, 128, 0.3);
                background-color: #fff;
                .events-message {
                    padding: 15px 15px 0;
                }
                h3 {
                    width: 100%;
                    color: #5a5a5a;
                    font-size: 16px;
                    margin: 0px;
                    font-weight: 700;
                    padding-bottom: 10px;
                    position: relative;
                    z-index: 110;
                    text-transform: uppercase;
                }
                p {
                    font-size: 14px;
                    font-weight: 500;
                }
                .mini-logo {
                    vertical-align: sub !important;
                }
                .event-date {
                    font-weight: 700;
                    display: block;
                }
                .btn-article {
                    background-color: #eae6e7;
                    margin: 10px auto;
                    width: 100%;
                    text-transform: uppercase;
                    color: #003da2;
                    font-size: 14px;
                    font-weight: 700;
                    padding: 5px 0 !important;
                }
            }
        }
    }
}

.fixtures-template {
    // Left hand grid, for fixture navigation elements etc
    .divFilter {
        border: 1px solid #dad9d9;
        padding: 15px;
        background-color: #fff;
        margin: 0 0 30px;
        h3 {
            font-weight: bold;
            text-transform: uppercase;
            font-size: 16px;
            color: #003da2;
            margin: 10px auto;
        }
        hr {
            border-top: 1px solid rgba(0, 61, 162, 0.3);
        }
        .divisionNav {
            padding: 0;
            margin: 15px 0;
            li {
                list-style-type: none;
                position: relative;
                background-color: #fff;
                &:hover {
                    background-color: #fff;
                }
                &.parent-one,
                &.parent-two,
                &.divisionNav,
                &.subNav {
                    transition: all .5s ease;
                    border: 1px solid #dad9d9;
                    span.message {
                        >a {
                            color: #fff;
                            text-decoration: none;
                        }
                    }
                    &:hover {
                        span.message {
                            cursor: pointer;
                            background-color: darken($brand-primary, 20%);
                        }
                        >span.toggle-one,
                        >span.toggle-two,
                        fixture-toggle-one {
                            cursor: pointer;
                            background-color: darken(#f2f2f2, 15%);
                        }
                    }
                    &.open {
                        border: 1px solid #dad9d9;
                        span.message {
                            background-color: #e8e7e7;
                        }
                        >.competitions-list,
                        >.sub-menu,
                        >.children {
                            background-color: #e8e7e7;
                            max-height: 9999px;
                            opacity: 1;
                        }
                    }
                }
                &.has-children,
                &.page_item_has_children {
                    transition: all .5s ease;
                    span.message {
                        background-color: #e8e7e7;
                        padding: 0;
                        a {
                            color: $brand-primary;
                        }
                    }
                    span.toggle-one {
                        background-color: #fff;
                    }
                    &:hover {
                        span.message {
                            cursor: pointer;
                            background-color: #fff;
                        }
                        span.toggle-one {
                            cursor: pointer;
                            background-color: #fff;
                        }
                    }
                    &.open {
                        >.competitions-list,
                        >.sub-menu,
                        >.children {
                            background-color: #fff;
                            max-height: 9999px;
                            opacity: 1;
                        }
                    }
                }
                &.has-children>.sub-menu>.has-children,
                &.page_item_has_children>.children>.page_item_has_children {
                    transition: all .5s ease;
                    span.message {
                        background-color: #fff;
                        padding: 0;
                        a {
                            color: $brand-primary;
                            &:hover {
                                color: #fff;
                            }
                        }
                    }
                    span.toggle-one {
                        background-color: #fff;
                    }
                    >&:hover {
                        span.message {
                            cursor: pointer;
                            background-color: darken($brand-primary, 25%);
                        }
                        span.toggle-one {
                            cursor: pointer;
                            background-color: darken(#f2f2f2, 15%);
                        }
                    }
                }
                &.menu-item {
                    span.message {
                        a {
                            &:hover {
                                color: #fff;
                                text-decoration: none;
                            }
                        }
                    }
                }
                span.message {
                    padding: 15px;
                    color: #fff;
                    background-color: #e8e7e7;
                    width: 100%;
                    display: block;
                    position: relative;
                    transition: .5s all ease;
                    &.menuHead {
                        background-color: darken($brand-primary, 25%) !important;
                        &:hover {
                            text-decoration: none;
                        }
                    }
                }
                span.toggle-one,
                span.toggle-two,
                span.fixture-toggle-one {
                    position: absolute;
                    top: 0;
                    right: 0;
                    padding: 15px;
                    background-color: #ffffff;
                    // border-left: 1px solid #dad9d9;
                    // border-top: 1px solid #dad9d9;
                    transition: .5s all ease;
                    &.rotate {
                        border-bottom: 0;
                        border-left: 0;
                        span {
                            -ms-transform: rotate(180deg);
                            /* IE 9 */
                            -webkit-transform: rotate(180deg);
                            /* Chrome, Safari, Opera */
                            transform: rotate(180deg);
                        }
                    }
                    span {
                        transition: all .5s ease;
                        color: #003da2;
                    }
                }
                .competitions-list,
                .sub-menu,
                .children {
                    margin: 0;
                    padding: 0px;
                    max-height: 0;
                    overflow: hidden;
                    opacity: 0;
                    transition: all .5s ease;
                    background-color: #e8e7e7;
                    li {
                        a {
                            display: block;
                            width: 100%;
                            padding: 15px;
                            margin: 0px;
                            transition: .5s all ease;
                            color: $brand-primary;
                            text-decoration: none;
                            &:hover {
                                color: #fff;
                                background-color: $brand-primary;
                                padding-left: 25px;
                            }
                        }
                    }
                }
                &.subNav {
                    >.sub-menu>li>.sub-menu,
                    >.sub-menu>li>.children {
                        border-left: 5px solid $brand-primary;
                        a {
                            padding: 15px 0 15px 30px;
                            &:hover {
                                padding: 15px 0 15px 45px;
                            }
                        }
                        >li>.sub-menu,
                        >li>.children {
                            border-left: 5px solid $brand-primary;
                            a {
                                padding: 15px 0 15px 45px;
                                &:hover {
                                    padding: 15px 0 15px 65px;
                                }
                            }
                        }
                    }
                }
                &.current_page_item,
                &.open {
                    >a {
                        background-color: $brand-primary;
                        color: #fff !important;
                    }
                }
            }
        }
    }
    // Right hand grid, fixtures & matches tables etc
    .tableWrap {
        h2 {
            font-weight: bold;
            min-height: 45px;
            text-transform: uppercase;
            font-size: 25px;
            color: $brand-primary;
            margin: 0 auto 20px;
        }
        .fixtureTable {
            margin: 0 0 50px;
            .table td,
            .table th {
                padding: 15px;
            }
            table {
                thead {
                    background-color: #dddddd;
                }
            }
        }
        .previousTable {
            margin: 0 0 50px;
            table {
                thead {
                    background-color: #dddddd;
                }
            }
        }
        .upTable {
            margin: 0 0 50px;
            table {
                thead {
                    background-color: #dddddd;
                }
            }
        }
        table {
            .scores {
                vertical-align: middle;
            }
            .thedate {
                padding: 15px 8px;
                background-color: #dddddd;
            }
            .key {
                background-color: #ddd;
                font-weight: bold;
            }
        }
    }
    .side-menu {
        // Left hand grid, for fixture navigation elements etc
        .divFilter {
            background-color: #fff;
            padding: 0 !important;
            margin: 0 0 30px;
            h3 {
                font-weight: bold;
                text-transform: uppercase;
                font-size: 16px;
                color: #003da2;
                margin: 10px auto;
            }
            .divisionNav {
                padding: 0;
                margin: 15px 0;
                .icon-arrow-down {
                    &:before {
                        content: "\f078";
                        position: absolute;
                        right: 5px;
                        top: 5px;
                        font: normal normal normal 14px/1 FontAwesome;
                        background-color: #f2f2f2;
                        padding: 10px;
                        color: $brand-primary;
                    }
                }
                li {
                    color: #fff;
                    background-color: #003da2;
                    width: 100%;
                    display: block;
                    position: relative;
                    transition: .5s all ease;
                    list-style-type: none;
                    &.parent-one,
                    &.parent-two,
                    &.divisionNav {
                        transition: all .5s ease;
                        position: relative;
                        border: 1px solid $brand-primary;
                        &:hover {
                            span.message {
                                cursor: pointer;
                                background-color: darken($brand-primary, 20%);
                            }
                            span.toggle-one,
                            span.toggle-two,
                            fixture-toggle-one {
                                cursor: pointer;
                                background-color: darken(#f2f2f2, 15%);
                            }
                        }
                        &.open {
                            span.message {
                                background-color: darken($brand-primary, 20%);
                            }
                            .competitions-list {
                                background-color: darken(#f2f2f2, 15%);
                            }
                        }
                    }
                    a {
                        padding: 15px;
                        color: #fff;
                        background-color: #003da2;
                        display: inline-block;
                        position: relative;
                        transition: .5s all ease;
                    }
                    .competitions-list {
                        margin: 0;
                        padding: 0px;
                        max-height: 0;
                        overflow: hidden;
                        opacity: 0;
                        transition: all .5s ease;
                        background-color: #f2f2f2;
                        li {
                            a {
                                display: inline-block;
                                width: 80%;
                                padding: 15px;
                                color: #fff;
                                margin: 0px;
                                transition: .5s all ease;
                                text-decoration: none;
                                &:hover {
                                    color: #fff;
                                    background-color: $brand-primary;
                                    padding-left: 10px;
                                }
                            }
                        }
                    }
                    .competitions-list-two {
                        padding-left: 15px;
                        background-color: #003da2;
                        max-height: 9999px;
                        opacity: 1;
                    }
                }
            }
        }
    }

    h2 img.team-logo{
        float:right;
        max-height:66px;
    }
}

.page-header {
    h1 {
        font-size: 40px;
    }
}

.internal-banner {
    img {
        @media (max-width:$screen-xs-max){
            max-height: 250px;
        }
    }
}

#search-term {
    padding: 0 15px;
    height: 45px;
    background-color: #efefef;
    border: 0 none;
    color: #000;
    min-width: 22em;
    margin-right: .5em;
    width: 90%;
    border: 1px solid #d1d1d1;
    border-radius: 4px;
}

.find-club-btn {
    display: inline-block;
    background-color: #003da2;
    color: #fff;
    text-transform: uppercase;
    max-width: 100%;
    padding: 10px 0;
    transition: all .5s ease;
    text-align: center;
    &:hover {
        background-color: #00173c;
        text-decoration: none;
        color: #fff;
    }
}

.table-head {
    background-color: #ddd;
}

.map-container {
    padding-bottom: 10px;
}

.team-grid {
    .player {
        background-color: $brand-primary;
        margin: 0 0 20px;
        &:hover {
            cursor: pointer;
        }
        h3 {
            margin: 0;
            padding: 20px 0;
            color: #fff;
        }
    }
}

.wpcf7-form{
    >p{
        float: left;
        width: 100%;
    }
    >label {
        display: block;
        float: left;
        width: 100%;
        margin-top: 10px;
        font-size: 14px;
    }
    >span {
        display: block;
        float: left;
        width: 100%;
    }
    .asterisk{
        color: #db2d2d;
        float: left !important;
        padding-right: 5px;
        width: auto !important;
    }    
    >.row{
        margin-bottom: 20px;
    }
    .wpcf7-recaptcha{
        float: left;
        margin-bottom: 15px;
    }
    [class*='col-sm-']> .wpcf7-submit{
        width: 100%;
        background: #003da2;
        border: 1px solid #003da2;
        border-radius: 0;
        color: #fff;
        font-weight: 400;
        font-style: normal;
        padding: 15px 25px;
        text-transform: uppercase;
    }

    .wpcf7-textarea, .wpcf7-text, .wpcf7-select{
        display: block;
        width: 100%;
        padding: 6px 12px;
        font-size: 14px;
        color: #555;
        background-color: #fff;
        background-image: none;
        border: 1px solid #ccc;
        border-radius: 4px;
        box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
        -webkit-transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
        -o-transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
        transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    }
    .wpcf7-text, .wpcf7-select {
        height: 34px;
        /*line-height: 1.428571429;*/
    }

    .form-group label {
        display: block;
        float: left;
        width: 100%;
        margin-top: 10px;
        font-size: 14px;
    }
    .form-group {
        min-height: 90px;
    }
}

#geoError, .error{
    padding: 15px;
}

#mixContainer .mix{
    display: none;
}
#mixContainer{
    min-height: 50vh;
}
#mixFilters{
    list-style: none;
    padding-left: 0;
}

.filter{
    display: block;
    padding: 5px 0;
    font-size: 14px;
    &:hover,
        &:focus{
        text-decoration: none;
    }
}
.filter.active{
    &:before{
        content: "\f105";
        font-family: fontawesome;
        padding-right: 2px;
    }
}

// Season Fixtures (homepage)

.league-select{

    padding: $grid-gutter-width / 3  $grid-gutter-width / 2  0  $grid-gutter-width;
    position: absolute;
    top:0;
    width: 100%;
    z-index: 100;
    @media (min-width:$screen-lg-min){
        width: 58.3333333333%;
    }
    @media (max-width:$screen-md-max){
        top: auto;
        order: 2;
    }
    button{
        width: 100%;
        font-size: 16px;
        padding: 15px;
        white-space: normal;
        text-transform: uppercase;
        .caret{
            border-width: 8px;
            margin-top: 7px; 
        }
    }
}
.league-dropdown{
    margin-left: 30px;
    margin-right: 15px;
    width: calc(100% - 45px);
    border-radius: 0;
    background: $brand-primary;
    >li>a{
        color: #fff;
    }
}
.team-links-row {
    position:absolute;
    bottom:15px;
    width:100%;
    @media(max-width:$screen-sm-max) {
        position:relative;
    }
}
.fixtures-row{
    background: #fff;
    float: left;
    margin-bottom: $grid-gutter-width / 2;
    @media(min-width:$screen-sm-min){
        height: calc(100% - 156px);
    }
    @media(min-width:$screen-md-min){
        height: calc(100% - 186px);
    }
    @media(min-width:$screen-lg-min){
        height: calc(100% - 206px);
    }
    @media (max-width:$screen-md-max){
        display: flex;
        flex-direction: column;
    }
}
.home-fixtures {
    h2 {
        font-size:36px;
    }
}
.fixture-list-container{
    box-shadow: -1px 0 0 #b8b8b8 inset;
    max-height: 100%;
    overflow: auto;
    @media (max-width:$screen-md-max){
        max-height: 80%;
        order: 3;
    }
    .fixture-list{
        list-style: none;
        padding-left: 0;
        @media (max-width:$screen-xs-max){
            >li:nth-child(even){
                background-color: rgba(0,0,0,.1);   
            }
        }
    }

    .fixture{

        // box-shadow: 0 0 0 1px $brand-primary inset;
        // border-right: 1px solid #b8b8b8;
        cursor: pointer;
        float: left;
        width: 100%;
        overflow: hidden;
        position: relative;
        transition: .25s all ease;
        @media (max-width:$screen-xs-max){
            padding-top: 10px;
            padding-bottom: 10px;
        }
        *{
            transition: .25s all ease;
        }
    }
    .fixture-spacer{
        min-height: 80px;
        @media (max-width:$screen-md-max){
            display:none;
        }
    }
    .fixture-date{
        background: rgba($brand-primary,.8);
        width: 20px;
        height: 200px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        span{
            display: block;
            transform: rotate(-90deg);
            color: rgba(0,0,0,.5);
            float: left;
            padding: 0 10px;
            position: absolute;
            margin: 0;
            text-align: center;
            width: 200px;
            top: 90px;
            left: -90px;
            white-space: nowrap;
        }
    }
    .team-home,
    .team-away{
        float: left;
        font-weight: 600;
        // width: calc(50% - 50px);
        opacity: .45;
        text-transform: uppercase;
        width: 100%;
        @include make-sm-column(5);
        padding: 0 25px;
        /* @media(min-width:$screen-md-min) and (max-width:$screen-md-max){
             width: 100%;
         }*/
        @media (min-width:$screen-sm-min){
            padding: 10px 25px;
        }
        >span{
            @media (min-width:$screen-md-min){
                display: flex;
                flex-direction: row;
                align-items: center;
                height: 100%;
                min-height: 20px;
            }
        }
    }
    .team-home{
        text-align: center;
        @media (min-width:$screen-md-min){
            text-align: right;
        }

    }
    .team-away{
        text-align: center;
        @media (min-width:$screen-md-min){
            text-align: left;
        }

    }
    .fixture-vs{
        clear: both;
        float: none;
        margin: 0 auto;
        width: 150px;
        position: relative;
        opacity: .66;
        @include make-sm-column(2);
        padding: 0 30px;
        @media (min-width:$screen-sm-min){
            clear: none;
            padding: 10px 0;
        }
        /*background-image: url(../images/scottish-hockey-device-lower.png);
        background-repeat: no-repeat;
        background-position: center center;*/
        &:before{
            height: 100%;
            content: '';
            display: block;
            left: 0;
            width: 100%;//calc(100% - 30px);

            background-image: url(../images/scottish-hockey-device-lower.png);
            background-repeat: no-repeat;
            background-position: center center;
            position: absolute;
            z-index: 10;
            @media(min-width:$screen-sm-min){
                height: calc(100% - 20px);
            }
        }

    }
    .score-home,
    .score-away{
        display: inline-block;
        font-weight: bold;
        font-size: 16px;
        margin-bottom: 0;
        padding: 5px 0;
        @media(min-width:$screen-md-min) and (max-width:$screen-md-max){
            width: 50%;
            text-align: center;
        }
        >span{
            display: flex;
            flex-direction: row;
            align-items: center;
            flex-grow: 1;
            height: 100%;
            min-height: 20px;
        }
    }
    .score-away{
        float: right;
        >span{
            float: right;
            text-align: right;
        }
    }

    li.fixture.fixture-active{
        background: #fff;
        padding-top: 0;
        padding-bottom: 0;
        border-right: 1px solid transparent;
        border-top: 1px solid #b8b8b8;
        border-bottom: 1px solid #b8b8b8;
        box-shadow: 2px 0 0 #fff;
        background-color: #fff;
        z-index: 10;
        .fixture-date{
            background: $brand-primary;
            span{
                color: #fff;
            }
        }
        .team-home,
        .team-away{
            opacity: 1;
            font-weight: bold;

            @include make-sm-column(5);
            @include make-lg-column(4);
            padding: 10px 25px;
            @media (min-width:$screen-lg-min){
                font-size: 16px;
                height: 105px;
            }

        }
        .team-home{
            @media (min-width:$screen-md-min){
                text-align: left;  
            }
            padding-left: 35px;
        }
        .team-away{
            @media (min-width:$screen-md-min){
                text-align: right;  
            }
            >span{
                text-align: right;
            }
        }
        .fixture-vs{
            &:before{
                //  width: calc(100% - 30px);
            }
            opacity: 1;

            @include make-sm-column(2);
            @include make-lg-column(4);
            padding-left: 0;
            padding-right: 0;
        }
        .score-home,
        .score-away{
            background-image: url(../images/team-home.png);
            background-repeat: no-repeat;
            background-position: center center;
            background-size: contain;
            width: 45%;
            @media (min-width:$screen-lg-min){
                height: 75px;
            }
        }
        .score-away{
            background-image: url(../images/team-away.png);
        }
    }
}
.match {
    margin:25px 0;
    @media (max-width:1285px) {
        font-size:14px;
        margin-top:25px;
    }
}

.fixture-detail-container{
    position: relative;
    height: 100%;
    min-height: 400px;
    @media (max-width:$screen-md-max){
        box-shadow: 0 -1px 0  #b8b8b8 inset;
        margin: 75px 0 0 15px;
    }
    @media (max-width:$screen-sm-max){
        min-height:575px;
    }
}
.fixture-detail{
    position: absolute;
    top:0;
    // border-left: 1px solid #b8b8b8;
    padding-bottom: 15px;
    pointer-events: none;
    height: 100%;
    width: calc(100% - 30px);
    &.in{
        pointer-events: auto;
    }
    h2{
        font-weight: 600;
        text-transform: uppercase;
        @media (max-width:$screen-md-max){
            font-size: 21px;
        }
    }
    p{
        font-weight: 800;
        a{
            float: right;
        }
    }
    .fixture-date,
    .fixture-date a,
    .fixture-location,
    .fixture-location a{
        color: #000;
        display: block;
        float: left;
        width: 100%;
        text-transform: uppercase;
        font-size: 18px;
    }
    .fixture-location a {
        font-size:26px;
    }
    .team-badge{
        margin-left: auto;
        margin-right: auto;
        @include img-responsive($display: block);
    }
    .btn{
        font-weight: 800;
        margin: 10px 0;
        text-align: center;
        text-transform: uppercase;
        width: 100%;
    }
    .team-home,
    .team-away{
        @include make-xs-column(6);
    }
}

.internal-leaderboard{
    background: $brand-primary;
    color: #fff;
    display: flex;
    align-items: stretch;
    float: left;
    width: 100%;
    position: relative;
    height: 155px;
    margin-bottom: 15px;
    @media(min-width:$screen-sm-min){
        height: 155px;   
    }
    @media(min-width:$screen-md-min){
        height: 170px;
        margin-bottom: 0;
    }
    @media(min-width:$screen-lg-min){
        height: 190px;
    }

    .leaderboard-img-container{
        margin-left: - $grid-gutter-width / 2;
        margin-right: - $grid-gutter-width / 2;
        height: 100%;
        @include make-xs-column(6);
        @include make-sm-column(7);
    }
    .leaderboard-img{
        background-size: cover;
        background-position: center center;
        height: 100%;
        overflow: hidden;
        img{
            width: auto;
            max-width: none;
            max-height: 100%;
            height: auto;
        }
        &:after{
            content: '';
            border-left: 40px solid rgb(194,211,239);
            box-shadow: 40px 0 0 mix(#fff, $brand-primary, 30%), -40px 0 0 rgba(255,255,255,.66), 79px 0 0 $brand-primary;
            position: absolute;
            display: block;
            transform: skew(-25deg);
            top: 0;
            right: 25px;
            height: 100%;
        }
    }
    .leaderboard-cta{
        position: relative;
        @include make-xs-column(6);
        @include make-sm-column(5);
        >div{
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: calc(100% - 30px);
            @media (min-width:$screen-md-min){
                width: 75%;
            }
        }
    }
    .leaderboard-lead{
        font-weight: bold;
        margin-bottom: 0;
    }
    h2{
        font-weight: 600;
        margin-top: 0;
    }
    .blue-btn{
        text-transform: uppercase;
        text-align: center;
        background-color: #042864;
        padding: 10px;
        color: #fff;
        width: 100%;
        font-weight: 700;
        margin: 10px auto 0;
        display: block
    }
}

// fix for homepage cols on mobile.
.main-banner-wrapper>.row>div{
    @media (max-width:$screen-xs-max){
        position: relative;
        overflow: hidden;

    }
}

.reduced .btn-scroll-top{
    opacity: 1;
}

.btn-scroll-top {
    opacity: 0;
    transition: 0.3s opacity ease; 
    background: #fff;
    position: fixed;
    bottom: 50px;
    right: 1px;
    border: 1px solid #cbcbcb;
    border-right: 0 none;
    font-weight: 700;
    padding: 10px 20px 10px 10px;
    max-width: 45px;
    white-space: nowrap;
    text-decoration: none;


    @media (min-width: 1640px) {
        max-width: 1000px;
    }

    .fa.circle-border {
        padding: 0;
        border: 1px solid;
        border-radius: 50%;
        width: 29px;
        height: 29px;
        text-align: center;
    }

    .fa {
        font-size: 21px;
        margin-right: 10px;
    }
}
span.fixture-toggle-one {
    cursor: pointer;
}


@media (max-width:560px) {
    .league-standings {
        .team-details {
            text-align:left !important;
            width:100%;
            box-sizing: border-box;
            display: block;
            float: left;
        }
        .mobile-border {
            border-bottom:1px solid;
        }
        .no-border {
            border:none !important;
        }
        tr {
            td {
                box-sizing: border-box;
                display: block;
                float: left;
                width: 100%;
            }
        }
    }
    .latest-results-table {
        tr {

            background-color: #fff;
            margin: 10px 0;
            border-top: 1px solid #000;
            border-bottom: 1px solid;
            td {
                border:none !important;
                box-sizing: border-box;
                display: block;
                float: left;
            }
            .date {
                width:10%;
                font-weight:600;
                float:right;
                margin-right:15px;
            }
            .division {
                font-weight:600;
                width:85%;
            }
            .team {
                width:40%;
            }
            .versus {
                width:10%;
            }
            .venue {
                font-weight:600;
                width:100%;
            }
        }
    }
}